<template>
  <v-container class="text-center" fill-height style="height: calc(100vh - 58px);">
    <v-row align="center">
      <v-col>
        <h1 class="display-2 primary--text">Ups! 404</h1>

        <p>No se encontró el recurso solicitado</p>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Page404",
  props: {
    lang: {
      type: String,
      default: "es"
    }
  },
  created() {
    this.$ssrContext && this.$ssrContext.res.status(404);
  }
};
</script>